<template>
  <div>
    <el-card class="cardHeight">
      <div class="cards height100">
        <div class="card_left">
          <div class="categoryCompany">
            <el-tree
              :filter-node-method="filterNode"
              ref="tree"
              highlight-current
              @node-click="handleNodeClick"
              :data="treeData"
              :render-content="renderContent"
              default-expand-all
              :props="defaultProps"
            ></el-tree>
          </div>
        </div>

        <div class="card_right">
          <el-card style="margin-top: 7px">
            <el-button
              @click="newBtn"
              icon="el-icon-plus"
              :style="[
                { 'margin-top': '8px' },
                { background: '#326ef0' },
                { color: '#fff' },
              ]"
              >新增</el-button
            >
            <div>
              <el-table
                border
                ref="table"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                height="550px"
              >
                <el-table-column
                  label="序号"
                  type="index"
                  width="50"
                  align="center"
                ></el-table-column>

                <el-table-column
                  label="视频名称"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">{{
                    scope.row.categoryname
                  }}</template>
                </el-table-column>
                <el-table-column
                  label="视频"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      @click="openElImageViewer(scope.row.videopath)"
                      size="small"
                      >查看视频</el-button
                    >
                    <!-- {{
                    scope.row.videopath
                  }} -->
                  </template>
                </el-table-column>
                <el-table-column
                  label="播放次数"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">{{
                    scope.row.numberplays
                  }}</template>
                </el-table-column>

                <el-table-column label="操作" width="135" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click="edit(scope.row)" size="small"
                      >编辑</el-button
                    >
                    <el-popconfirm
                      title="确定删除吗？"
                      @confirm="handleDelte(scope.row.id)"
                    >
                      <el-button slot="reference" style="color: red" type="text"
                        >删除</el-button
                      >
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <div class="block">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="queryInfo.currPage"
                  :page-sizes="[100, 200, 300, 400]"
                  :page-size="queryInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <el-dialog
        :title="treeForm.id ? '编辑' : '新增'"
        :visible.sync="dialogVisibleTopLevel"
        width="520px"
      >
        <el-form
          :model="treeForm"
          :rules="treeRules"
          label-width="130px"
          ref="treeForm"
        >
          <el-form-item label="新增知识库分类" required>
            <el-input
              v-model="treeForm.typeName"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序:" required>
            <el-input-number
              :step="1"
              step-strictly
              :min="1"
              v-model="treeForm.seq"
            ></el-input-number>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleTopLevel = false">取 消</el-button>
          <el-button type="primary" @click="saveTreeForm">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        :title="labelType2 == 'add' ? '新增' : '编辑'"
        :visible.sync="showDialog"
        width="800px"
      >
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="视频名称" prop="siteName">
                <el-input
                  v-model="form.categoryname"
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上传视频" prop="siteName">
                <el-upload
                  class="avatar-uploader"
                  :action="$uploadURL"
                  :show-file-list="false"
                  :on-success="handleSuccess"
                  :before-upload="beforeAvatarUploadVideo"
                >
                  <div v-if="form.videopath != ''" class="flex">
                    <div style="margin: 5px" class="imgBox">
                      <video :src="form.videopath" class="avatar" alt="" />
                      <div class="img-icon">
                        <i
                          class="el-icon-zoom-in"
                          @click.stop="openElImageViewer(form.videopath)"
                        ></i>
                        <i
                          class="el-icon-delete"
                          @click.stop="handelRemove()"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer">
          <el-button size="small" @click="showDialog = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSave"
            >确定</el-button
          >
        </span>
      </el-dialog>
    </el-card>
    <el-dialog title="视频" :visible.sync="dialogVisibleVideo" width="70%">
      <video
        width="100%"
        :src="dialogVideoUrl"
        alt=""
        class="bigImg"
        controls
      />
    </el-dialog>
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: {  ElImageViewer },
  data() {
    return {
      rules: {},
      treeRules: {},
      form: {
        videopath: "",
        categoryname: "",
      },
      treeForm: {
        typeName: "",
        seq: "",
      },
      dialogVisibleTopLevel: false, //新增知识库
      total: 0,
      currentTree: "",
      queryInfo: {
        currPage: 1,
        pageSize: 100,
        condition: {},
      },
      showDialog: false,
      labelType: "add",
      labelType2: "add",
      showElImageViewer: false,
      treeData: [],
      tableData: [],
      defaultProps: {
        children: "childrens",
        label: "typeName",
      },
      treeProps: {
        label: "typeName", // 显示节点文本的字段
        children: "childrens", // 子节点数组的字段
      },
      dialogVideoUrl: "",
      dialogVisibleVideo: false,
    };
  },
  created() {
    this.getTreeData();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.typeName.indexOf(value) !== -1;
    },
    openElImageViewer(item) {
      this.dialogVideoUrl = item;
      console.log(this.dialogVideoUrl, 267);
      this.dialogVisibleVideo = true;
    },
    handelRemove() {
      this.form.videopath = "";
    },
    handleSuccess(res) {
      this.form.videopath = res.data.url;
    },

    beforeAvatarUploadVideo(file) {
      console.log(file.type, "video");
      const VideoType =
        file.type === "video/mp4" ||
        file.type === "video/avi" ||
        file.type === "video/wmv" ||
        file.type === "video/rmvb";

      if (!VideoType) {
        this.$message.error("上传视频只能是 MP4 、AVI 、WMV 、RMVB 格式!");
      }
      return VideoType;
    },

    addTree(data) {
      this.currentTree = data;
      this.dialogVisibleTopLevel = true;
    },
    editTree(data) {
      this.treeForm = JSON.parse(JSON.stringify(data));
      this.dialogVisibleTopLevel = true;
    },

    //新增按钮
    newBtn() {
      this.labelType2 = "add";
      this.form = {};
      this.form.videopath = "";
      this.form.categoryname = "";
      this.showDialog = true;
    },

    // 添加tree
    async saveTreeForm() {
      if (this.labelType == "edit") {
        const res = await this.$http.post(
          "/knowledgeStructure/update",
          this.treeForm
        );
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.dialogVisibleTopLevel = false;
          this.getTreeData();
        } else {
          this.$message.error(res.data.message);
        }
      } else {
        const data = {
          createCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
            .userCode,
          createName: JSON.parse(localStorage.getItem("userInfo")).userInfo
            .name,
          parentCode: this.currentTree.typeCode,
          parentName: this.currentTree.typeName,
          typeName: this.treeForm.typeName,
          seq: this.treeForm.seq,
        };
        const res = await this.$http.post("/knowledgeStructure/add", data);
        if (res.data.code === 200) {
          this.$message.success("添加成功");
          this.dialogVisibleTopLevel = false;
          this.getTreeData();
        } else {
          this.$message.error(res.data.message);
        }
      }
    },
    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/knowledgebase/delete", { id: id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: "提示",
                message: "删除成功",
                showClose: true,
              });
              that.getList();
            } else {
              that.$notify.info({
                title: "提示",
                message: response.data.message,
                showClose: true,
              });
            }
          });
      }
    },
    handleNodeClick(obj) {
      this.currentTree = obj;
      this.queryInfo.condition.typeCode = this.currentTree.typeCode;
      this.getList();
      console.log(this.currentTree, 579);
    },

    delTree(data) {
      this.$confirm(`确认删除${data.typeName}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let { id, typeCode } = data;
          let res = await this.$http.post("knowledgeStructure/delete", {
            id,
            typeCode,
          });
          if (res.data.code == 200) {
            this.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
            });
            this.getTreeData();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {});
    },

    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>
            <span
              style={{
                "max-width": 188 - node.level * 18 + "px",
                width: 188 - node.level + "px",
                "font-weight": "600",
                "font-size": "15px",
                "margin-left": "6px",
                display: "inline-block",
                "white-space": "nowrap",
                "text-overflow": "ellipsis",
                overflow: "hidden",
              }}
            >
              {data.typeName}
            </span>
          </span>
          <span>
            <i
              on-click={() => {
                this.addTree(data);
              }}
              style={{ marginRight: "8px" }}
              class="el-icon-plus icon2 icons"
            ></i>
            <i
              on-click={() => {
                this.editTree(data);
              }}
              class="el-icon-edit icon2 icons"
              style={{ marginRight: "8px" }}
            ></i>
            {data.parentCode != "" && data.parentCode != null ? (
              <i
                on-click={() => {
                  this.delTree(data);
                }}
                class="el-icon-delete icon2 icons"
              ></i>
            ) : (
              ""
            )}
          </span>
        </span>
      );
    },

    async getTreeData() {
      let res = await this.$http.post("knowledgeStructure/getTree", {
        typeCode: "1001",
      });
      if (res.data.code == 200) {
        this.treeData = res.data.data.data;
        if (Array.isArray(res.data.data.data) && res.data.data.length > 0) {
          this.currentTree = res.data.data[0];
          this.getList();
          // this.getRoleList();
        }
      } else {
        this.$message.error(res.data.message);
      }
    },
    getList() {
      var that = this;

      that.$http
        .post("/knowledgebase/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },
    // 保存
    handleSave() {
      this.form.id ? this.editVideo() : this.addVideo();
    },
    async editVideo() {
      const res = await this.$http.post("/knowledgebase/update", this.form);
      if (res.data.code == 200) {
        this.$notify.success({
          title: "提示",
          message: "修改成功",
          showClose: true,
        });
        this.showDialog = false;
        this.getList();
      } else {
        this.$notify.info({
          title: "提示",
          message: res.data.message,
          showClose: true,
        });
      }
    },
    addVideo() {
      const data = {
        categoryname: this.form.categoryname,
        typeCode: this.currentTree.typeCode,
        category: this.currentTree.typeName,
        videopath: this.form.videopath,
      };

      this.$http
        .post("/knowledgebase/add", data)
        .then((res) => {
          if (res.data.code == 200) {
            this.$notify.success({
              title: "提示",
              message: "添加成功",
              showClose: true,
            });
            this.showDialog = false;
            this.getList();
          } else {
            this.$notify.info({
              title: "提示",
              message: res.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {});
    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    closeDrawer() {
      this.areaVisible = false;
    },
    // 获取新增数据
    async getRoleList() {
      const res = await this.$http.post("/roleInfo/getRoleList", {
        agentCode: this.currentTree.agentCode,
      });

      if (res.data.code == 200) {
        console.log("roleList", res.data.data);
        this.roleList = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    addUser() {},
  },
};
</script>

<style lang="less" scoped>
/deep/ .userInfoCard {
  .userInfoCard-card {
    .el-card__body {
      height: calc(100vh - 138px);
      overflow: auto;
    }
  }
  .auth {
    display: flex;
    font-size: 14px;
    .label {
      width: 90px;
      color: #aaa;
      flex-shrink: 0;
    }
    .label::after {
      content: ":";
      padding-left: 2px;
    }
    .value {
      color: #000;
    }
    .col {
      display: flex;
      flex-direction: column;
      .list {
        margin-bottom: 8px;
        .organName {
          margin-left: 12px;
        }
      }
    }
  }
  .main-card {
    margin-top: 12px;
    .name-card {
      display: flex;
      align-items: center;
      .name-left {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #d7d7d7;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        border-radius: 6px;
      }
      .name-right {
        margin-left: 12px;
        color: #333;
        display: flex;
        height: 50px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: space-between;
        .right-top {
          font-weight: 600;
          font-size: 15px;
        }
        .right-bottom {
          .label {
            color: #aaa;
            font-size: 14px;
          }
          .label::after {
            content: ":";
            padding-left: 2px;
          }
          .value {
            font-weight: 600;
            font-size: 15px;
            margin-left: 10px;
            color: #333;
          }
        }
      }
    }
    .lists {
      margin: 0;
      margin-top: 30px;
      .item {
        display: flex;
        font-size: 14px;
        margin-bottom: 10px;
        .label {
          width: 90px;
          color: #aaa;
        }
        .label::after {
          content: ":";
          padding-left: 2px;
        }
        .value {
          color: #000;
        }
      }
    }
  }
  .card-title {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
    .text {
      color: #333;
      font-weight: 600;
      font-size: 14px;
    }
    i {
      color: #dcdfe6;
      cursor: pointer;
      font-size: 20px;
      padding: 4px 8px;
    }
  }
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #02a7f0;
}
// .cardHeight {
//   height: calc(100vh - 80px);
// }
// .height100 {
//   height: 100%;
// }
.block-title {
  position: relative;
  width: fit-content;
  color: #7f7f7f;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}
.block-title::before {
  content: "";
  display: block;
  width: 3px;
  height: 90%;
  position: absolute;
  left: -14px;
  background-color: #02a7f0;
}
/deep/ .el-card__body {
  padding-bottom: 0;
}
.cards {
  width: 100%;
  display: flex;
  flex-grow: 0;
  .card_left {
    width: 280px;
    flex-shrink: 0;
    .top {
      display: flex;
      /deep/ .el-input__inner {
        height: 30px !important;
        line-height: 30px !important;
      }
      .el-button {
        height: 34px;
        border-radius: 6px;
      }
      .create_btn {
        background: #02a7f0;
        margin-left: 12px;
        i {
          font-size: 18px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
    .list {
      margin-top: 16px;
      margin-bottom: 0;
      height: calc(100vh - 150px);
      overflow: auto;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        cursor: pointer;
        height: 50px;
        list-style: none;
        background-color: #f2f2f2;
        color: #333;
        line-height: 50px;
        padding-right: 16px;
        padding-left: 40px;
        i {
          display: none;
        }
        > div {
          width: 220px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      li.active {
        background-color: #b2e4fb;
        font-weight: 600;
      }
      li:hover {
        background: darken(#f2f2f2, 15%);
        i {
          display: block;
        }
        i:hover {
          font-weight: 600;
        }
      }
      li.active:hover {
        background-color: #b2e4fb;
      }
    }
  }
  .card_right {
    background-color: #f5f7f9;
    margin-left: 16px;
    box-sizing: border-box;
    padding: 10px;
    flex-grow: 0;
    width: calc(100% - 280px);
  }
}
/deep/ .el-form-item__label::after {
  content: ":";
  padding-left: 2px;
}
/deep/ .online {
  color: #7f7f7f;
}
/deep/ .online::before {
  content: "";
}
/deep/ .samllPadding {
  padding: 0 4px;
}
.categoryCompany::-webkit-scrollbar {
  width: 6px;
}
.categoryCompany::-webkit-scrollbar-thumb {
  background-color: #02a7f0;
}
/deep/ .categoryCompany {
  margin-top: 16px;
  margin-bottom: 0;
  height: calc(100vh - 165px);
  overflow: auto;
  //    border: 1px dashed #02a7f0;
  .el-tree-node__content {
    height: 40px;
    .el-tree-node__label {
      font-size: 20px;
    }
  }
}
/deep/ .custom-tree-node {
  font-size: 18px;
  i {
    display: none;
  }
  i:hover {
    color: #02a7f0;
  }
}
/deep/ .el-tree-node__content:hover {
  i {
    display: inline-block;
  }
}
/deep/ .custom-tree-node {
  span {
    max-width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}
/deep/ .is-leaf {
  span {
    max-width: 230px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}

.main {
  padding: 0 20px;
  height: calc(100vh - 130px);
  overflow-y: scroll;
}
.drawer-footer {
  padding: 0 20px;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}

::v-deep .el-dialog__body {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}
</style>
